import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
// ---------------------------------------------
import Header from "../components/header"
import Layout from "../components/layout"
// import LightboxLink from "../components/lightbox/link";
import Picture from "../components/picture"
import Splash from "../components/splash"
import Transition, { Link } from "../components/transition"
import Revealer from "../components/revealer"
import Vimeo from "../components/vimeo"
import SEO from "../components/seo"
// Helpers
import resolveProjectUrl from "../helpers/resolveProjectUrl"

function useScrollObserver() {
  // const timer = useRef()
  const [ref, inView] = useInView({ threshold: 0.5 })
  const [show, setShow] = useState(false)
  // const [isBusy, setBusy] = useState(false)

  // useEffect(() => {

  // 	const handleScroll = () => {
  // 		setBusy(true)
  // 		clearTimeout(timer.current)
  // 		timer.current = setTimeout(() => {
  // 			setBusy(false)
  // 		}, 300)
  // 	}

  // 	if (inView) {
  // 		setShow(true)
  // 		window.addEventListener('scroll', handleScroll)
  // 	} else {
  // 		window.removeEventListener('scroll', handleScroll, false)
  // 	}

  // 	return () => {
  // 		clearTimeout(timer.current)
  // 		window.removeEventListener('scroll', handleScroll, false)
  // 	}
  // }, [inView])

  useEffect(() => {
    if (inView) {
      setShow(true)
    }
  }, [inView])

  const revealStyle = {
    opacity: show ? 1 : 0,
    transform: `translateY(${show ? "0" : "5%"})`,
    transition: "ease-out 1s",
  }

  return [ref, show, revealStyle]
}

const NewsThumbnail = props => {
  // useWhyDidYouUpdate('NewsThumbnail', props)

  const {
    item,
    focusClassName,
    handleLink,
    setBackgroundColour,
    setHighlight,
    artists,
    count,
  } = props
  const isArticle = item.article || item.articleHero.length
  const colour =
    item.colourSelection[0] && (item.colourSelection[0].colour || "#ccc")
  const project = item.projectLink[0]
  const artist = project
    ? artists.find(f => f.authorId === project.authorId)
    : null
  const cover = item.newsThumbnail[0] || (project && project.thumbnails[0])
  const coverSrc = cover && cover.source && cover.source[0]
  const isPortrait =
    (coverSrc && coverSrc.height / coverSrc.width > 1) ||
    (cover && cover.videoWidth && cover.videoHeight / cover.videoWidth > 1)

  // const to = project && artist ? `/project/${artist.slug}/${project.slug}-${project.id}` : isArticle ? `/article/${item.slug}` : false;
  const to = resolveProjectUrl(
    project,
    artist,
    isArticle ? `/article/${item.slug}` : false
  )

  // UX
  const [ref, show, revealStyle] = useScrollObserver()

  // UI
  const handlers = {
    onTouchStart: () => setBackgroundColour(colour),
    onMouseEnter: () => {
      setHighlight(item)
      setBackgroundColour(colour)
    },
    onMouseLeave: () => setHighlight(false),
  }

  return (
    to && (
      <div
        className={`collage-item ${
          isPortrait ? "portrait" : "landscape"
        } ${focusClassName}`}
        ref={ref}
      >
        <div style={revealStyle}>
          <Link to={to} handleLink={handleLink}>
            <div
              className={`featured-project__asset ${focusClassName}`}
              {...handlers}
            >
              {cover ? (
                cover.vimeoUrl ? (
                  <Vimeo {...cover} reveal={{ isVisible: show }} />
                ) : (
                  <div className="image-thumb is-loaded">
                    <Picture {...cover.source[0]} resize={{ width: 640 }} />
                  </div>
                )
              ) : (
                <Vimeo reveal={{ isVisible: show }} />
              )}
            </div>
          </Link>
          {!isArticle ? (
            <div className="project__info">
              <div className="project__name">
                <div className="title uppercase">
                  {artist ? artist.title : item.title}
                </div>
                <i>for</i>{" "}
                {project && (
                  <span className={true ? "" : "uppercase"}>
                    {project.client}
                  </span>
                )}
              </div>
              {item.newsItemSummary && (
                <div
                  className="project__summary"
                  dangerouslySetInnerHTML={{ __html: item.newsItemSummary }}
                />
              )}
            </div>
          ) : (
            <div className="project__info">
              <div className="project__name">
                <span className="title uppercase">
                  {artist ? artist.title : item.title}
                </span>
                <span className="heading--4 details">
                  {item.newsCategory[0].title}
                </span>
                {item.headline && (
                  <div>
                    <i>{item.headline}</i>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  )
}

const NewsPage = ({ pageContext, data, location, handleLink }) => {
  const categories = data.craft.categories
  const news = data.craft.news
  const [backgroundColour, setBackgroundColour] = useState(
    news &&
      news[0] &&
      news[0].colourSelection[0] &&
      news[0].colourSelection[0].colour
  )
  const [highlight, setHighlight] = useState(false)
  const [activeFilter, setActiveFilter] = useState(
    pageContext && pageContext.slug
  )
  const handleFilterClick = newFilter => {
    setActiveFilter(newFilter)
    window.history.pushState(
      null,
      null,
      newFilter ? `/news/${newFilter}` : `/news`
    )
  }

  return (
    <Layout>
      <SEO title="News" background={backgroundColour} />
      <Transition
        location={location}
        intro={() => (
          <Splash colour={backgroundColour}>
            <h1>News</h1>
          </Splash>
        )}
        introKey={"news"}
        render={handleLink => (
          <>
            <Header handleLink={handleLink} />
            <main className="head-offset">
              <div className="project-filter">
                <ul className="project-filter__list">
                  <li
                    className={`project-filter__list-item ${
                      !activeFilter ? "active" : ""
                    }`}
                    onClick={e => handleFilterClick(false)}
                  >
                    All
                  </li>
                  {categories &&
                    categories.map(node => (
                      <li
                        className={`project-filter__list-item ${
                          node.slug === activeFilter ? "active" : ""
                        }`}
                        onClick={e => handleFilterClick(node.slug)}
                        key={`filter-${node.slug}`}
                      >
                        {node.title}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="collage">
                {news.map((item, i) => {
                  const focusClassName = !highlight
                    ? ""
                    : item.slug === highlight.slug
                    ? "in-focus"
                    : "not-in-focus"

                  return (
                    (!activeFilter ||
                      activeFilter === item.newsCategory[0].slug) && (
                      <NewsThumbnail
                        item={item}
                        focusClassName={focusClassName}
                        handleLink={handleLink}
                        setBackgroundColour={setBackgroundColour}
                        setHighlight={setHighlight}
                        artists={data.craft.artists}
                        debug={i === 0}
                        count={i}
                        key={`${item.id}-${item.slug}`}
                      />
                    )
                  )
                })}
              </div>
            </main>
          </>
        )}
      />
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NewsPage

export const pageQuery = graphql`
  query NewsPageQuery {
    craft {
      categories(group: "NewsTypes") {
        ... on Craft_newsTypes_Category {
          title
          slug
        }
      }
      news: entries(type: "News") {
        ... on Craft_news_news_Entry {
          ...NewsEntry
        }
      }
      artists: entries(type: "Artists") {
        ... on Craft_artists_artists_Entry {
          id
          title
          slug
          authorId
        }
      }
    }
  }
`
